<script>
    import { datadogRum } from '@datadog/browser-rum';

    export default {
        props: {
            label: {
                type: String,
                default: '',
            },
            labelContext: {
                type: Object,
                default: () => {},
            },
            action: Function,
            // uses fontawesome
            symbol: String,
            secondary: Boolean,
            secondaryGrey: Boolean,
            warning: Boolean,
            danger: Boolean,
            disabled: Boolean,
            small: Boolean,
            extraSmall: Boolean,
            micro: Boolean,
            outline: Boolean,
            datadogAction: String,
            square: {
                type: Boolean,
                default: false,
            },
            noMargin: {
                type: Boolean,
                default: false,
            },
            noText: {
                type: Boolean,
                default: false,
            },
            propagateClick: {
                type: Boolean,
                default: false,
            },
            invertedIcon: {
                type: Boolean,
                default: false,
            },
            noBackground: {
                type: Boolean,
                default: false,
            },
            fatIcon: {
                type: Boolean,
                default: false,
            },
            smallIcon: {
                type: Boolean,
                default: false,
            },
            fullwidth: {
                type: Boolean,
                default: false,
            },
            type: {
                type: String,
                default: () => 'button',
            },
            noBorder: Boolean,
            breakWords: Boolean,
            id: String,
            red: Boolean,
            brightRed: Boolean,
            white: Boolean,
            loading: Boolean,
        },
        data () {
            return {
                busy: false,
            };
        },
        computed: {
            symbolClass () {
                if (this.symbol) {
                    let klass = {
                        fa: true,
                    };
                    klass['fa-' + this.symbol] = true;
                    return klass;
                } else {
                    return {};
                }
            },
        },
        emits: ['click'],
        methods: {
            doClick (e) {
                this.$emit('click', e);
                if (!this.propagateClick) {
                    e.stopPropagation();
                }
                if (this.action && !this.busy && !this.disabled) {
                    if (this.datadogAction) {
                        datadogRum.addAction(this.datadogAction);
                    }
                    this.busy = true;
                    Promise.resolve(this.action())
                        .then(() => {
                            this.busy = false;
                        });
                }
            },
        },
    };
</script>

<template>
    <button
        class='inline-block bg-transparent border-0 p-0 text-current outline-none shadow-none font-normal text-base leading-normal align-baseline'
        :disabled='disabled'
        @click='doClick'
        :type='type'
        :id='id'
    >
        <div
            class='cb-friendly-button'
            :class='{
                secondary:secondary,
                "secondary-grey":secondaryGrey,
                warning,
                danger,
                enabled:!(disabled||busy),
                disabled:disabled,
                busy:busy||loading,
                small,
                "extra-small": extraSmall,
                "no-margin": noMargin,
                square,
                micro,
                invertedIcon,
                noBackground,
                fatIcon,
                smallIcon,
                fullwidth,
                noBorder,
                red,
                "bright-red": brightRed,
                "white": white,
            }'
        >
            <span v-if='$slots.iconLeft' class='mr-3'>
                <slot name='iconLeft'></slot>
            </span>
            <span v-if='!noText' :class='[{"whitespace-normal break-words": breakWords}, symbol ? invertedIcon ? "ml-3 mr-0" : "mr-3 ml-0" : ""]'>
                {{ $t(label, labelContext) }}
                <slot></slot>
            </span>
            <i v-if='symbol' :class='[symbolClass]'></i>
            <span v-if='$slots.iconRight' class='ml-3'>
                <slot name='iconRight'></slot>
            </span>
        </div>
    </button>
</template>

<style scoped>
    .cb-friendly-button {
        @apply bg-blue-500;
        position: relative;
        top: 0px;
        height: 60px;
        line-height: 1em;
        font-size: 18px;
        padding: 0px 30px;
        border-radius: 30px;
        border: solid 2px transparent;
        cursor: pointer;
        color: white;
        box-shadow: 0px 0px 0px #62709a6b;
        user-select: none;
        transition: all linear 100ms;
        margin: 10px 10px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        white-space: nowrap;
    }

    .cb-friendly-button.noBackground,
    .cb-friendly-button.enabled.noBackground:hover:active,
    .cb-friendly-button.enabled.noBackground:hover
    {
        @apply text-blue-400;
        background-color: transparent;
        border: none;
        box-shadow: none;
    }
    .cb-friendly-button.noBorder {
        border: none;
    }
    .cb-friendly-button.invertedIcon {
        flex-direction: row-reverse;
    }
    .cb-friendly-button.small {
        height: 48px;
        padding: 0 20px;
    }
    .cb-friendly-button.extra-small {
        @apply text-base;
        height: 34px;
        font-size: 14px;
        padding: 0 10px;
    }
    .cb-friendly-button.micro {
        @apply text-base;
        height: 28px;
        font-size: 14px;
        padding: 0 8px;
    }
    .cb-friendly-button.no-margin {
        margin: 0;
    }
    .cb-friendly-button.square {
        @apply rounded-md;
    }
    .cb-friendly-button.secondary
    {
        @apply text-grey-500 border-grey-500;
        top: 0;
        background: transparent;
        box-shadow: none;
    }
    .cb-friendly-button.enabled.secondary:hover:active,
    .cb-friendly-button.enabled.secondary:hover
    {
        @apply text-grey-600 border-grey-600;
        top: 0;
        background: transparent;
        box-shadow: none;
    }
    .cb-friendly-button.secondary-grey,
    .cb-friendly-button.enabled.secondary-grey:hover:active,
    .cb-friendly-button.enabled.secondary-grey:hover
    {
        @apply text-grey-700 bg-grey-250 border-grey-250;
        top: 0;
        box-shadow: none;
    }
    .cb-friendly-button.warning,
    .cb-friendly-button.enabled.warning:hover:active,
    .cb-friendly-button.enabled.warning:hover
    {
        @apply bg-orange-300;
        top: 0;
        box-shadow: none;
    }
    .cb-friendly-button.warning.noBackground,
    .cb-friendly-button.enabled.warning.noBackground:hover:active,
    .cb-friendly-button.enabled.warning.noBackground:hover
    {
        @apply text-orange-300;
        background: transparent;
        box-shadow: none;
        border: none;
    }
    .cb-friendly-button.danger,
    .cb-friendly-button.enabled.danger:hover:active,
    .cb-friendly-button.enabled.danger:hover
    {
        @apply bg-red-300;
        top: 0;
        box-shadow: none;
    }
    .cb-friendly-button.danger.noBackground,
    .cb-friendly-button.enabled.danger.noBackground:hover:active,
    .cb-friendly-button.enabled.danger.noBackground:hover
    {
        @apply text-red-300;
        background: transparent;
        box-shadow: none;
        border: none;
    }

    .cb-friendly-button.fullwidth {
        @apply w-full;
    }
    .cb-friendly-button i {
        font-size: 16px;
    }
    .cb-friendly-button.fatIcon i {
        font-size: 18px;
    }
    .cb-friendly-button.smallIcon i {
        font-size: 10px;
    }
    .cb-friendly-button.enabled:hover {
        @apply bg-blue-400;
    }
    .cb-friendly-button.enabled:hover:active {
        @apply bg-blue-400;
        top: 0px;
        box-shadow: 0px 0px 0px #62709a6b;
    }
    .cb-friendly-button.enabled.warning:hover {
        @apply bg-orange-200;
        top: -2px;
        box-shadow: 0px 2px 6px #62709a6b;
    }
    .cb-friendly-button.enabled.warning:hover:active {
        @apply bg-orange-100;
        top: 0px;
        box-shadow: 0px 0px 0px #7a5f5b;
    }
    .cb-friendly-button.enabled.danger:hover {
        @apply bg-red-200;
        top: -2px;
        box-shadow: 0px 2px 6px #62709a6b;
    }
    .cb-friendly-button.enabled.danger:hover:active {
        @apply bg-red-100;
        top: 0px;
        box-shadow: 0px 0px 0px #7a5f5b;
    }
    .cb-friendly-button.disabled {
        @apply bg-blue-200;
        cursor: not-allowed;
        @apply opacity-50;
    }
    .cb-friendly-button.secondary.disabled {
        cursor: default;
        color: #d6def7;
        background: transparent;
        border-color: #c9d4f3;
    }
    .cb-friendly-button.busy {
        cursor: default;
        background: linear-gradient(90deg, #3a72ff 0%, #0A42E6 35%, #0A42E6 35%, #3a72ff 100%);
        background-position: 0px -3px;
        background-size: 200px 200px;
        animation: friendly-bars 800ms linear infinite;
    }
    .cb-friendly-button.secondary.busy {
        background: linear-gradient(90deg, #81a1ff54, #5e85ff11, #81a1ff54);
        background-position: 0px -3px;
        background-size: 100px 100px;
        animation: friendly-bars 800ms linear infinite;
    }
    .cb-friendly-button.warning.busy {
        background: linear-gradient(90deg, #FF9112, #FF8900, #FF9112);
        background-position: 0px -3px;
        background-size: 100px 100px;
        animation: friendly-bars 800ms linear infinite;
    }
    .cb-friendly-button.danger.busy {
        background: linear-gradient(90deg, #f37062, #e74c3c, #f37062);
        background-position: 0px -3px;
        background-size: 100px 100px;
        animation: friendly-bars 800ms linear infinite;
    }

    @keyframes friendly-bars {
        0% {
            background-position: -200px;
        }
        100% {
            background-position: 0;
        }
    }
    .cb-friendly-button.red {
        background-color: rgba(255, 87, 51, 0.7);
    }
    .cb-friendly-button.enabled.red {
        background-color: rgba(255, 87, 51, 0.7);
    }
    .cb-friendly-button.bright-red {
        background-color: rgb(231, 76, 60);
    }
    .cb-friendly-button.enabled.bright-red {
        background-color: rgb(231, 76, 60);
    }
    .cb-friendly-button.white {
        background-color: rgb(255, 255, 255);
    }
    .cb-friendly-button.enabled.white {
        background-color: rgb(255, 255, 255);
    }
    .cb-friendly-button.enabled.white:hover {
        background-color: rgba(255, 255, 255, 0.781);
    }
</style>
