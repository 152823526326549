<script setup>
import { inject, computed, ref } from 'vue';

const steps = inject('steps');
const currentStepIndex = inject('currentStepIndex');

const currentStepTitle = computed(() => steps.value[currentStepIndex.value]?.header.title);
const currentStepDescription = computed(() => steps.value[currentStepIndex.value]?.header.description);
const isTransitioning = ref(false);
const isAbsolute = ref(false);

function handleBeforeEnter () {
    isAbsolute.value = true;
    isTransitioning.value = true;
}

function handleAfterEnter () {
    isAbsolute.value = false;
    isTransitioning.value = false;  // End transition
}

function handleBeforeLeave () {
    isTransitioning.value = true;
}
</script>

<template>
    <div class='mb-14 text-center w-full h-auto relative'>
        <Transition
            name='fade-overlap'
            mode='default'
            @before-enter='handleBeforeEnter'
            @after-enter='handleAfterEnter'
            @before-leave='handleBeforeLeave'
        >
            <div
                v-if='currentStepTitle || currentStepDescription'
                :key='currentStepTitle + currentStepDescription'
                :class='isAbsolute ? "absolute inset-0 w-full" : ""'
                class='step-content'
            >
                <h1 v-if='currentStepTitle' class='text-6xl font-normal m-0'>
                    {{ currentStepTitle }}
                </h1>
                <p v-if='currentStepDescription' class='mt-4 text-xl font-normal text-gray-500'>
                    {{ currentStepDescription }}
                </p>
            </div>
        </Transition>
    </div>
</template>

<style scoped>
.fade-overlap-enter-active, .fade-overlap-leave-active {
    transition: opacity 0.3s;
}
.fade-overlap-enter-from, .fade-overlap-leave-to {
    opacity: 0;
}
</style>
