<script setup>
import { inject, defineProps, ref, computed, watch } from 'vue';

defineOptions({
    name: 'WizardStep',
});

const props = defineProps({
    title: {
        type: String,
    },
    icon: {
        type: String,
    },
    desc: {
        type: String,
    },
    headerTitle: {
        type: String,
    },
    headerDescription: {
        type: String,
    },
});

const stepElement = ref();

const steps = inject('steps');
const currentStepIndex = inject('currentStepIndex');
const registerStep = inject('registerStep');

const stepIndex = ref(steps.value.length);

const shouldBeShown = computed(() => {
    return (currentStepIndex.value === stepIndex.value);
});

watch(() => props.headerTitle, (value, _) => {
    steps.value[stepIndex.value].header.title = value;
});

watch(() => props.headerDescription, (value, _) => {
    steps.value[stepIndex.value].header.description = value;
});

registerStep({
    title: props.title,
    icon: props.icon,
    desc: props.desc,
    index: stepIndex.value,
    header: {
        title: props.headerTitle,
        description: props.headerDescription,
    },
    element: stepElement,
});
</script>

<template>
    <div ref='stepElement' class='transition-all duration-500 ease-in-out-quart' :class='{"opacity-0 pointer-events-none" : !shouldBeShown}'>
        <slot></slot>
    </div>
</template>
