<script setup>
import { useSlots, ref } from 'vue';

const hasMultipleElements = ref(false);
const slots = useSlots();
if (slots.default && slots.default().length > 1) {
    hasMultipleElements.value = true;
}
</script>

<template>
    <div
        class='w-100 flex justify-end mt-8'
        :class='{"justify-between": hasMultipleElements}'
    >
        <slot></slot>
    </div>
</template>
