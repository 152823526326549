<!-- eslint-disable vue/multi-word-component-names -->
<script setup>
import { defineProps } from 'vue';
import * as icons from '@heroicons/vue/20/solid';

const props = defineProps({
    name: {
        type: String,
    },
});

const component = icons[`${props.name}Icon`];
</script>

<template>
    <component :is='component' />
</template>
