<script setup>
import { inject, ref, computed } from 'vue';
import Icon from '@/components/Icon';
const steps = inject('steps');
const currentStepIndex = inject('currentStepIndex');
const sidePanelIcons = ref();
const sidePanel = ref();

function isActive (step) {
    return (
        currentStepIndex.value === step.index
    );
}

const activeBlockPosition = computed(() => {
    let position = 0;
    if (sidePanelIcons.value) {
        position = sidePanelIcons.value[`${currentStepIndex.value}`].offsetTop;
    }
    return `transform: translateY(${position}px);`;
});
</script>

<template>
    <div
        class='flex flex-col mr-8 relative'
        ref='sidePanel'
    >
        <div
            class='transition-transform ease-in-out-quart duration-500 block w-14 h-14 bg-grey-700 rounded-lg absolute z-0'
            :style='activeBlockPosition'
        ></div>
        <div
            v-for='(step, index) in steps'
            :key='index'
            class='flex mb-10 last:mb-0 z-10'
        >
            <div
                class='bg-grey-700/5 flex items-center justify-center w-14 h-14 rounded-lg'
                ref='sidePanelIcons'
            >
                <Icon
                    :name='step.icon'
                    class='transition-colors duration-500 ease-in-out-quart text-grey-400 w-7 h-7'
                    :class='{ "text-grey-50": isActive(step) }'
                />
            </div>
            <div
                class='ml-3 inline-block flex flex-col justify-center'
            >
                <h3
                    class='transition-colors ease-linear text-grey-500 text-base font-bold m-0'
                    :class='{ "text-grey-700": isActive(step) }'
                >
                    {{ step.title }}
                </h3>
                <p
                    class='transition-colors ease-linear text-grey-300 text-base m-0'
                    :class='{ "text-grey-700": isActive(step) }'
                    v-if='step.desc'
                >
                    {{ step.desc }}
                </p>
            </div>
        </div>
    </div>
</template>
